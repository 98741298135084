import React from "react";
import styles from './Comment.module.scss';


export default function Comment(){
    return(
        <div className={styles.com}>
            <h4>Здравствуйте!</h4>
            <br/>
            <p>Меня зовут Сергей Сатаров. Я врач-реабилитолог из Саратова.</p>
            <p>На данном ресурсе Вы можете ознакомиться с возможными вариантами реабилитационных целей и диагнозов по МКФ.</p>
            <p>Информация отобрана с учетом группы пациентов, относящейся ко 2 этапу реабилитации после инсульта, а именно ШРМ 4, цели для ШРМ 5 указаны отдельно.</p>
            <p>Портал <b>медреаб.рф</b> будет полезен для практикующих врачей, студентов и ординаторов медицинских вузов.</p>
            
            <p>Проект развивается.</p>
            <br/>
            <div className={styles.photo}></div>
            <h4>Связь со мной:</h4>
       {/*      <br/> */}
            <p><b>medreab@list.ru</b></p>
        </div>
    );
}