import React from "react";
import styles from './Categories.module.scss';

export default function Categories(props){

    const categories=[
        {
            key:'all',
            name:'все'
        },
        {
            key:'ходьба',
            name:'ходьба'
        },
        {
            key:'ходьба + рука',
            name:'ходьба + рука'
        },
        {
            key:'ходьба + речь',
            name:'ходьба + речь'
        },
        {
            key:'ходьба + рука + речь',
            name:'ходьба + рука + речь'
        },
        {
            key:'шрм5',
            name:'шрм 5'
        }

    ]




    return(
        <div className={styles.categories}>
            {categories.map(el=>(
                <div key={el.key} onClick={()=>props.chooseCategory(el.key)}>{el.name}</div>
            ))}
        </div>
    )
}

