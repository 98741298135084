import React from "react";
import styles from './Header.module.scss';






export default function Header() {
   
    return (
        <header>
            <div>
                <span className={styles.logo}>медреаб</span>
                <ul className={styles.nav}>
                    <li><a href="https://rutube.ru/channel/25379367/" target="_blank" rel="noopener noreferrer">видео</a></li>
                    <li><a href="https://t.me/medreab" target="_blank" rel="noopener noreferrer">телеграм</a></li>
                </ul>
            </div>
            <div className={styles.presentation}></div>

        </header>

    );
}









