import React from "react";
import styles from './ShowFullItem.module.scss'
export default function ShowFullItem(props) {
    return (
        <div className={styles.fullItem}>
            <div className={styles.div}>
                <div className={styles.but}><button onClick={() => props.onShowItem(props.item)}>X</button>
                    <b>Цель реабилитации:</b></div>
                <h1>{props.item.purpose}</h1>
                <b>Диагноз по МКФ до реабилитации:</b>
                {props.item.before_b1 ? <p>{props.item.before_b1}</p> : null}
                {props.item.before_b2 ? <p>{props.item.before_b2}</p> : null}
                {props.item.before_b3 ? <p>{props.item.before_b3}</p> : null}
                {props.item.before_s1 ? <p>{props.item.before_s1}</p> : null}
                {props.item.before_d1 ? <p>{props.item.before_d1}</p> : null}
                {props.item.before_d2 ? <p>{props.item.before_d2}</p> : null}
                {props.item.before_d3 ? <p>{props.item.before_d3}</p> : null}
                {props.item.before_d4 ? <p>{props.item.before_d4}</p> : null}
                {props.item.before_d5 ? <p>{props.item.before_d5}</p> : null}
                {props.item.before_e1 ? <p>{props.item.before_e1}</p> : null}
                <b>Диагноз по МКФ после реабилитации:</b>
                {props.item.after_b1 ? <p>{props.item.after_b1}</p> : null}
                {props.item.after_b2 ? <p>{props.item.after_b2}</p> : null}
                {props.item.after_b3 ? <p>{props.item.after_b3}</p> : null}
                {props.item.after_s1 ? <p>{props.item.after_s1}</p> : null}
                {props.item.after_d1 ? <p>{props.item.after_d1}</p> : null}
                {props.item.after_d2 ? <p>{props.item.after_d2}</p> : null}
                {props.item.after_d3 ? <p>{props.item.after_d3}</p> : null}
                {props.item.after_d4 ? <p>{props.item.after_d4}</p> : null}
                {props.item.after_d5 ? <p>{props.item.after_d5}</p> : null}
                {props.item.after_e1 ? <p>{props.item.after_e1}</p> : null}
            </div>
        </div>
    );
}

