import React from "react";
import styles from './Item.module.scss'

export default function Item(props){
    return(
        <div className={styles.item}>
            <p onClick={()=>props.onShowItem(props.item)}>{props.item.purpose}</p>

        </div>
    );
}