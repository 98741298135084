import React, { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './index.scss'
import Items from "./components/Items";
import Categories from './components/Categories';
import ShowFullItem from './components/ShowFullItem';
import Comment from './components/Comment';





function App() {
  const [items] = useState([
    {
      id: 1,
      category: 'ходьба',
      purpose: 'расширение двигательной активности до уверенной ходьбы на дистанции более 500 м',
      before_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4501.2 Ходьба на дальние расстояния (умеренные затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.1 Опорные функции руки или ноги (легкие нарушения)',
      after_s1: 's11008.1 Структура долей коры (легкие нарушения)',
      after_d1: 'd4501.1 Ходьба на дальние расстояния (легкие затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 2,
      category: 'ходьба',
      purpose: 'расширение двигательной активности до уверенной ходьбы на короткие дистанции',
      before_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4500.3 Ходьба на короткие расстояния (тяжелые затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      after_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      after_d1: 'd4500.2 Ходьба на короткие расстояния (умеренные затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'


    },
    {
      id: 3,
      category: 'ходьба + рука',
      purpose: 'расширение двигательной активности и двигательных навыков до уверенной ходьбы на дистанции более 500 м и уверенного исполнения повседневного распорядка',
      before_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      before_b2: 'b7601.2 Контроль сложных произвольных движений (умеренные нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4501.2 Ходьба на дальние расстояния (умеренные затруднения)',
      before_d2: 'd2302.2 Исполнение повседневного распорядка (умеренные затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.1 Опорные функции руки или ноги (легкие нарушения)',
      after_b2: 'b7601.1 Контроль сложных произвольных движений (легкие нарушения)',
      after_s1: 's11008.1 Структура долей коры (легкие нарушения)',
      after_d1: 'd4501.1 Ходьба на дальние расстояния (легкие затруднения)',
      after_d2: 'd2302.1 Исполнение повседневного распорядка (легкие затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 4,
      category: 'ходьба + рука',
      purpose: 'расширение двигательной активности и двигательных навыков до уверенной ходьбы на короткие дистанции и возможного исполнения повседневного распорядка',
      before_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      before_b2: 'b7600.3 Контроль простых произвольных движений (тяжелые нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4500.3 Ходьба на короткие расстояния (тяжелые затруднения)',
      before_d2: 'd2302.3 Исполнение повседневного распорядка (тяжелые затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      after_b2: 'b7600.2 Контроль простых произвольных движений (умеренные нарушения)',
      after_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      after_d1: 'd4500.2 Ходьба на короткие расстояния (умеренные затруднения)',
      after_d2: 'd2302.2 Исполнение повседневного распорядка (умеренные затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 5,
      category: 'ходьба + речь',
      purpose: 'расширение двигательной активности до уверенной ходьбы на дистанции более 500 м, улучшение речевой функции',
      before_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      before_b2: 'b320.2 Функции артикуляции (умеренные нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4501.2 Ходьба на дальние расстояния (умеренные затруднения)',
      before_d2: 'd330.2 Речь (умеренные затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.1 Опорные функции руки или ноги (легкие нарушения)',
      after_b2: 'b320.1 Функции артикуляции (легкие нарушения)',
      after_s1: 's11008.1 Структура долей коры (легкие нарушения)',
      after_d1: 'd4501.1 Ходьба на дальние расстояния (легкие затруднения)',
      after_d2: 'd330.1 Речь (легкие затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 6,
      category: 'ходьба + речь',
      purpose: 'расширение двигательной активности до уверенной ходьбы на короткие дистанции, улучшение речевой функции',
      before_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      before_b2: 'b320.3 Функции артикуляции (тяжелые нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4500.3 Ходьба на короткие расстояния (тяжелые затруднения)',
      before_d2: 'd330.3 Речь (тяжелые затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      after_b2: 'b320.2 Функции артикуляции (умеренные нарушения)',
      after_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      after_d1: 'd4500.2 Ходьба на короткие расстояния (умеренные затруднения)',
      after_d2: 'd330.2 Речь (умеренные затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 7,
      category: 'ходьба + рука + речь',
      purpose: ' расширение двигательной активности до уверенной ходьбы на дистанции более 500 м и уверенного исполнения повседневного распорядка, улучшение функции артикуляции',
      before_b1: 'b7603.2 Опорные функции руки или ноги (умеренные нарушения)',
      before_b2: 'b7601.2 Контроль сложных произвольных движений (умеренные нарушения)',
      before_b3: 'b320.2 Функции артикуляции (умеренные нарушения)',
      before_s1: 's11008.2 Структура долей коры (умеренные нарушения)',
      before_d1: 'd4501.2 Ходьба на дальние расстояния (умеренные затруднения)',
      before_d2: 'd2302.2 Исполнение повседневного распорядка (умеренные затруднения)',
      before_d3: 'd330.2 Речь (умеренные затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.1 Опорные функции руки или ноги (легкие нарушения)',
      after_b2: 'b7601.1 Контроль сложных произвольных движений (легкие нарушения)',
      after_b3: 'b320.1 Функции артикуляции (легкие нарушения)',
      after_s1: 's11008.1 Структура долей коры (легкие нарушения)',
      after_d1: 'b7603.1 Опорные функции руки или ноги (легкие нарушения)',
      after_d2: 'd2302.1 Исполнение повседневного распорядка (легкие затруднения)',
      after_d3: 'd330.1 Речь (легкие затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 8,
      category: 'шрм5',
      purpose: ' расширение двигательной активности до  присаживания в постели',
      before_b1: 'b7603.4 Опорные функции руки или ноги (абсолютные нарушения)',
      before_s1: 's11008.3 Структура долей коры (тяжелые нарушения)',
      before_d1: 'd4100.3 Изменение позы при положении лежа (тяжелые затруднения)',
      before_d2: 'd4103.3 Изменение позы при положении сидя (тяжелые затруднения)',
      before_d3: 'd4153.4 Нахождение в положении сидя (абсолютные затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      after_s1: 's11008.3 Структура долей коры (тяжелые нарушения)',
      after_d1: 'd4100.1 Изменение позы при положении лежа (легкие затруднения)',
      after_d2: 'd4103.1 Изменение позы при положении сидя (легкие затруднения)',
      after_d3: 'd4153.2 Нахождение в положении сидя (умеренные затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 9,
      category: 'шрм5',
      purpose: ' расширение двигательной активности до самостоятельного присаживания, приема пищи и вставания с постели',
      before_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      before_s1: 's11008.3 Структура долей коры (тяжелые нарушения)',
      before_d1: 'd4100.2 Изменение позы при положении лежа (умеренные затруднения)',
      before_d2: 'd4103.3 Изменение позы при положении сидя (тяжелые затруднения)',
      before_d3: 'd4153.2 Нахождение в положении сидя (умеренные затруднения)',
      before_d4: 'd4154.4 Нахождение в положении стоя (абсолютные затруднения)',
      before_d5: 'd550.4 Прием пищи (абсолютные затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      after_s1: 's11008.3 Структура долей коры (тяжелые нарушения)',
      after_d1: 'd4100.2 Изменение позы при положении лежа (умеренные затруднения)',
      after_d2: 'd4103.2 Изменение позы при положении сидя (умеренные затруднения)',
      after_d3: 'd4153.1 Нахождение в положении сидя (легкие затруднения)',
      after_d4: 'd4154.3 Нахождение в положении стоя (тяжелые затруднения)',
      after_d5: 'd550.3 Прием пищи (тяжелые затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
    {
      id: 10,
      category: 'шрм5',
      purpose: 'расширение двигательной активности до самостоятельного вставания и ходьбы на короткие дистанции',
      before_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      before_s1: 's11008.3 Структура долей коры (тяжелые нарушения)',
      before_d1: 'd4100.2 Изменение позы при положении лежа (умеренные затруднения)',
      before_d2: 'd4103.3 Изменение позы при положении сидя (тяжелые затруднения)',
      before_d3: 'd4153.2 Нахождение в положении сидя (умеренные затруднения)',
      before_d4: 'd4154.3 Нахождение в положении стоя (тяжелые затруднения)',
      before_d5: 'd4500.3 Ходьба на короткие расстояния (тяжелые затруднения)',
      before_e1: 'e310+2 Семья и ближайшие родственники (умеренные облегчающие факторы)',
      after_b1: 'b7603.3 Опорные функции руки или ноги (тяжелые нарушения)',
      after_s1: 's11008.3 Структура долей коры (тяжелые нарушения)',
      after_d1: 'd4100.2 Изменение позы при положении лежа (умеренные затруднения)',
      after_d2: 'd4103.2 Изменение позы при положении сидя (умеренные затруднения)',
      after_d3: 'd4153.1 Нахождение в положении сидя (легкие затруднения)',
      after_d4: 'd4154.2 Нахождение в положении стоя (умеренные затруднения)',
      after_d5: 'd4500.2 Ходьба на короткие расстояния (умеренные затруднения)',
      after_e1: 'e310+3 Семья и ближайшие родственники (выраженные облегчающие факторы)'
    },
  ]);
  
  const [currentItems, setCurrentItems] = useState([]);
  const [showFullItem, setShowFullItem] = useState(false);
  const [fullItem, setFullItem] = useState({});



  const chooseCategory = (category) => {
    if (category === 'all') {
      setCurrentItems(items);
    }
    else {
      setCurrentItems(items.filter((el) => el.category === category));
    }
  }

  useEffect(() => {
    chooseCategory('all');
  }, []);

  const onShowItem = (item) => {
    setFullItem(item);
    setShowFullItem(!showFullItem);
  }

  

  return (
    <div className="wrapper">
      <Header />
      <Categories chooseCategory={chooseCategory} />
      <Items allItems={currentItems} onShowItem={onShowItem} />
      {showFullItem && <ShowFullItem onShowItem={onShowItem} item={fullItem} />}
      <Comment />
      <Footer />
    </div>
  );
}

export default App;